$lightMainColor: rgba(0, 159, 227, 1);
$lightTransparentMainColor: rgba(0, 159, 227, 0.1);
$darkMainColor: rgba(3, 73, 145, 1);
$lightGrayColor: rgba(200, 198, 198, 1);
$mainGradientColor: linear-gradient(to right, $lightMainColor, $darkMainColor);
$lightBlackColor: rgba(0, 0, 0, 0.5);

$defaultElementHeight: 4.5vh;
$defaultLineHeight: 24px;

:export {
    lightMainColor: $lightMainColor;
    darkMainColor: $darkMainColor;
    lightGrayColor: $lightGrayColor;
    lightTransparentMainColor: $lightTransparentMainColor;
    mainGradientColor: $mainGradientColor;
    lightBlackColor: $lightBlackColor;
    defaultElementHeight: $defaultElementHeight;
    defaultLineHeight: $defaultLineHeight;
}

@import '../../../../styles/variables.module.scss';
@import '../../../../styles/common.scss';

$rowPadding: 5vw;

.status {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $lightGrayColor;
    justify-content: end;
}

.user {
    display: flex;
    flex-direction: row;
    margin-left: $rowPadding;
    padding: 0;
    width: 100%;
    .avatar {
        min-width: 3vw;
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
    }

    .nameLogin {
        display: flex;
        flex-direction: column;
        .name {
            font-size: 20px;
            color: $lightMainColor;
            font-weight: 700;
        }
        .login {
            font-size: 18px;
            color: $lightGrayColor;
        }
    }
}

.iconEditDiv {
    display: flex;
    align-items: center;
    justify-content: start;
}

.iconStatisticsDiv {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: $rowPadding;
}

@import '../../../../../styles/variables.module.scss';

.newUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon {
        width: 20%;
        display: flex;
        height: auto;
    }
    .text {
        color: $darkMainColor;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
    }
}

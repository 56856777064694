@import '../../../styles/variables.module.scss';
@import '../../../styles/common.scss';

.firstRow {
    .text {
        color: $darkMainColor;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
    }
}

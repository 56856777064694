@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import 'styles/variables.module.scss';
@import 'react-bootstrap-typeahead/css/Typeahead.css';

body {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: $defaultLineHeight;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.table > :not(:first-child) {
    border-top: 1px solid $lightBlackColor;
}

tr {
    vertical-align: middle;
}

.react-bootstrap-table-pagination-list-hidden {
    display: none;
}

@media (min-width: 576px) {
    .modal-sm {
        max-width: 400px;
    }
}

.invalid-feedback {
    display: block;
}

.smallFont {
    font-size: 0.7em;
}

.btn-check:focus + .btn,
.btn:focus {
    box-shadow: none;
    outline: 0;
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
    color: $darkMainColor;
    background-color: $lightTransparentMainColor;
    border-color: transparent;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $darkMainColor;
}

a.active {
    background-color: $lightTransparentMainColor;
}

.modal {
    overflow: hidden;
}

.react-bootstrap-table {
    max-height: 55vh;
    overflow-y: auto;
}

.img-responsive {
    height: auto;
    width: 100%;
}

.modal-content {
    border-radius: 24px;
}

.btn-close {
    width: 2em;
    height: 2em;
    background: transparent url(./assets/images/close.svg) center/2em auto no-repeat;
}

.uppercase-text {
    text-transform: uppercase;
}

.hstack-wrap {
    flex-flow: wrap;
}

.form-check-label {
    color: $darkMainColor;
    font-size: 1rem;
}

.no-padding {
    padding: 0;
}

.long-text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
}

.font-24 {
    font-size: 24px;
    line-height: 28px;
}

.font-responsive-lg {
    font-size: 1.8vw;
    line-height: 2vw;
}

.font-responsive-md {
    font-size: 1vw;
    line-height: 1.2vw;
}

.cursor-pointer {
    cursor: pointer;
}

.px-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
}

.px-8 {
    padding-right: 8.5rem !important;
    padding-left: 8.5rem !important;
}

th {
    position: sticky;
    top: 0;
}

.userChangesTable-row {
    &:hover {
        background-color: $lightTransparentMainColor;
        cursor: pointer;
    }
}
